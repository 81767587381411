<template>
    <div class="border xs-fill-white">
        <div class="container">
            <div id="page-payment">
                <center>
                    <form id="payment-form" @submit="checkForm" method="post" action="" novalidate="true">
                        <div data-v-a2eefc38="" class="xs-text-center heading-hr t16 ">
                            {{ $t("card_information")}}
                        </div>

                        <div id="container">
                            <!--<div id="header">【テスト】日本ピザハット株式会社&nbsp;
                                <hr width="100%" color="#ADD8E6" size="2">
                            </div>-->
                            <div id="box">
                                <!--<div id="title">{{$t('credit_payment')}}</div>-->
                                <div id="logo_box">
                                    <img class="card card-visa"
                                            src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/visa.gif">
                                    <img class="card card-mastercard"
                                            src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/master.gif">

                                    <img class="card card-jcb"
                                            src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/jcb.gif">
                                    <img class="card card-american-express"
                                            src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/amex.gif">
                                    <img class="card card-diners-club"
                                            src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/diners.gif">
                                </div>
                            </div>
                            <br/>
                            <div class="tb-payment-detail">
                                <div class="tr">
                                    <div class="td">
                                        {{$t('credit_card_number')}}
                                    </div>
                                    <div class="td">
                                        <input type="text" id="ccNumber" v-model="ccNumber" name="ccNumber" maxlength="16" size="22" value=""
                                               class="input"
                                               v-bind:class="{ 'has-errors': errors.ccNumber , 'has-success': errors.ccNumber === false}">

                                        <div v-if="errors.ccNumber" class="error  xs-text-red  ">
                                            <span>{{ errors.ccNumber }}</span>
                                        </div>
                                        <!--（{{ $t('credit_no_hyphen') }}）-->
                                    </div>
                                </div>
                                <div class="tr">
                                    <div class="td">{{$t('credit_expiration_date')}}</div>
                                    <div class="td date-select">
                                        <div class="t-item">
                                            <select id="ccExpirationMonth" name="ccExpirationMonth" v-model="ccExpirationMonth" class="input"
                                                    v-bind:class="{ 'has-errors': errors.ccExpirationMonth , 'has-success': errors.ccExpirationMonth === false}">
                                              <option value="0">{{$t('month')}}</option>
                                              <option v-for="item in monthList"
                                                      v-bind:value="item">
                                                {{ item }}
                                              </option>
                                            </select>
                                            <div v-if="errors.ccExpirationMonth" class="error  xs-text-red  ">
                                                <span>{{ errors.ccExpirationMonth }}</span>
                                            </div>
                                        </div>
                                        <div class="t-item dash">／</div>
                                        <div class="t-item">
                                            <select id="ccExpirationYear" name="ccExpirationYear" v-model="ccExpirationYear" class="input"
                                                  v-bind:class="{ 'has-errors': errors.ccExpirationYear , 'has-success': errors.ccExpirationYear === false}">
                                              <option value="0">{{$t('year')}}</option>
                                              <option v-for="item in yearList"
                                                      v-bind:value="item">
                                                  {{ item }}
                                              </option>
                                            </select>
                                            <div v-if="errors.ccExpirationYear" class="error  xs-text-red  ">
                                                <span>{{ errors.ccExpirationYear }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="tr">
                                    <div class="td">
                                        {{$t('credit_security_code')}}
                                    </div>
                                    <div class="td">
                                        <div class="security-code-box">
                                            <div style="display: flex; flex-direction: column; justify-content: flex-start">
                                                <input type="text" id="securityCode" name="securityCode" v-model="securityCode" maxlength="4" size="4" value=""
                                                       class="input"
                                                       v-bind:class="{ 'has-errors': errors.securityCode , 'has-success': errors.securityCode === false}">
                                                <div v-if="errors.securityCode" class="error  xs-text-red  ">
                                                    <span>{{ errors.securityCode }}</span>
                                                </div>
                                            </div>

                                            <div class="td security-info-text">
                                                <img src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/cvv2.gif"
                                                     class="i1">

                                                <div id="text09" class="info_heading">{{$t('credit_what_security_code')}}</div>
                                                <div id="text10">{{$t('credit_security_code_info1')}}
                                                </div>

                                                <!--<div id="text10">
                                                    {{$t('credit_security_code_info2')}}
                                                </div>-->
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <input type="hidden" name="dealingsType" value="10">
                            <input type="hidden" id="merchant_id" name="merchant_id" v-model="merchant_id">
                            <input type="hidden" id="service_id" name="service_id" v-model="service_id">
                            <input type="hidden" id="token" name="token" v-model="token">
                            <input type="hidden" id="tokenKey" name="tokenKey" v-model="tokenKey">

                        </div>
                    </form>

                    <a class="btn-next" href="#" v-on:click="checkForm">
                        {{$t('button_pay')}}
                        <!--<img src="https://fcds-jpbeta.s3-ap-northeast-1.amazonaws.com/fcdsstatic/img/next.gif">-->
                    </a>
                </center>
            </div>
        </div>

    </div>
</template>
<script>
import moment from 'moment'
import creditCardType from 'credit-card-type'

var vm = this
function doSubmit (vm) {
  // Call of the token generation logic
  vm.callEventBus(vm.$const.EVENT_BUS.LOADING, true)

  com_sbps_system.generateToken({
    merchantId: document.getElementById('merchant_id').value,
    serviceId: document.getElementById('service_id').value,
    ccNumber: document.getElementById('ccNumber').value,
    ccExpiration: document.getElementById('ccExpirationYear').value + document.getElementById('ccExpirationMonth').value,
    securityCode: document.getElementById('securityCode').value
  }, afterGenerateToken)
}

var afterGenerateToken = function (response) {
  if (response.result === 'OK') {
    document.getElementById('token').value = response.tokenResponse.token
    document.getElementById('tokenKey').value = response.tokenResponse.tokenKey
    let data = {
      'order': vm.transaction,
      'token': response.tokenResponse.token,
      'tokenKey': response.tokenResponse.tokenKey,
      'ccNumber': response.tokenResponse.maskedCcNumber,
      'ccExpiration': response.tokenResponse.ccExpiration,
      'free1': vm.free1,
      'free2': vm.free2,
      'free3': vm.free3
    }
    vm.axios.apiPayment.makeCreditPayment(data,
      function (response) {
        window.location = 'https://' + window.location.hostname + '/payment-status/' + response.data.data.item.transaction.uuid
        // window.location = 'https://jpdev.pizzahut.jp/payment-status/' + response.data.data.item.transaction.uuid
        // vm.$router.push({ 'name': 'payment-status', 'params': { 'transaction': response.data.data.item.transaction.uuid } })
      }, function (data) {
        let layer = {
          id: vm.cart.uuid,
          code: 'payment-err',
          type: 'payment',
          message: 'card-invalid'
        }

        vm.callEventBus(vm.$const.EVENT_BUS.LOADING, false)
        vm.eventTrackingOrderError(layer)
      }
    )
  } else {
    alert(vm.$t('credit_card_info_invalid'))
  }
}
export default {
  components: {
    moment
  },
  watch: {
    ccNumber: function (newValue, oldValue) {
      let visaCards = creditCardType(newValue)
      let cardList = document.getElementsByClassName('card')

      for (let i = 0; i < cardList.length; i++) {
        if (newValue) {
          cardList[i].className = cardList[i].className.replace(/\bactive\b/g, '')

          if (visaCards && visaCards[0] && visaCards[0].type) {
            let cardItem = document.getElementsByClassName('card-' + visaCards[0].type)
            this.cardType = visaCards[0].type

            if (cardItem && cardItem[0]) {
              cardItem[0].classList.add('active')
            }
            if (visaCards[0].type === creditCardType.types.AMERICAN_EXPRESS) {
              this.codeMaxLength = 4
            } else {
              this.codeMaxLength = 3
            }
          }
        } else {
          cardList[i].className = cardList[i].className.replace(/\bactive\b/g, '')
        }
      }

      this.checkSecurityCode(this.securityCode)

      let numbers = /^[-+]?[0-9]+$/
      let message = ''
      if (newValue && !newValue.match(numbers)) {
        this.ccNumber = oldValue
        return
      }

      if (!this.ccNumber) {
        message = this.$t('validate_credit_card_number_required')
      } else if (this.ccNumber.length < 8 || this.ccNumber.length > 16) {
        message = this.$t('validate_credit_card_number_invalid')
      } else {
        message = null
      }

      this.errors.ccNumber = message
    },
    securityCode: function (newValue, oldValue) {
      this.checkSecurityCode(newValue, oldValue)
    }
  },
  mounted: function () {
    vm = this
    vm.$cart.init(vm)
    if (vm.cart.uuid) {
      let form = {
        id: 'formCreditCard',
        name: 'card-details',
        category: 'payment',
        target: 'https://' + window.location.hostname
      }
      this.eventTrackingPayment(vm, form)
    }
  },
  data () {
    return {
      cardType: null,
      codeMaxLength: 4,
      processing: false,
      ccNumber: '',
      ccExpirationMonth: 0,
      ccExpirationYear: 0,
      securityCode: '',
      total: '',
      item: '',
      transaction: '',
      merchant_id: '',
      service_id: '',
      free1: '',
      free2: '',
      free3: '',
      token: '',
      tokenKey: '',
      errors: {
        ccNumber: '',
        ccExpirationMonth: '',
        ccExpirationYear: '',
        securityCode: ''
      }
    }
  },
  computed: {
    yearList () {
      let year = new Date().getFullYear()
      let list = []
      for (let i = year; i < year + 20; i++) {
        list.push(i)
      }

      return list
    },
    monthList () {
      let list = []
      let m = moment()
      for (let i = 0; i < 12; i++) {
        list.push(m.month(i).format('MM'))
      }

      return list
    }
  },
  methods: {
    checkForm (e) {
      vm.processing = true
      e.preventDefault()

      let flag = true

      var numbers = /^[-+]?[0-9]+$/
      if (this.ccNumber && this.ccNumber.match(numbers) && this.ccNumber.length >= 8 && this.ccNumber.length <= 16) {
        this.errors.ccNumber = false
      } else {
        this.errors.ccNumber = this.$t('validate_credit_card_number_invalid')
        flag = false
      }

      if (this.securityCode && this.securityCode.match(numbers) && (this.securityCode.length === this.codeMaxLength)) {
        this.errors.securityCode = false
      } else {
        this.errors.securityCode = this.$t('validate_credit_security_code_invalid')
        flag = false
      }

      if (this.ccExpirationMonth) {
        this.errors.ccExpirationMonth = false
      } else {
        this.errors.ccExpirationMonth = this.$t('validate_credit_month_required')
        flag = false
      }

      if (this.ccExpirationYear) {
        this.errors.ccExpirationYear = false
      } else {
        this.errors.ccExpirationYear = this.$t('validate_credit_year_required')
        flag = false
      }

      if (flag) {
        this.eventTrackingOrderSubmit(this.cart.uuid)
        doSubmit(this)
      }

      return false
    },
    loadScript () {
      // (function (d, s, id) {
      //   var js, fjs = d.getElementsByTagName(s)[0]
      //   if (d.getElementById(id)) return
      //   js = d.createElement(s); js.id = id
      //   js.src = 'https://token.sps-system.com/sbpstoken/com_sbps_system_token.js'
      //   fjs.parentNode.insertBefore(js, fjs)
      // }(document, 'script', 'com_sbps_system_token'))
      let comSbpsSystemToken = document.createElement('script')
      comSbpsSystemToken.setAttribute('src', 'https://token.sps-system.com/sbpstoken/com_sbps_system_token.js')
      document.head.appendChild(comSbpsSystemToken)
    },
    checkSecurityCode (newValue, oldValue) {
      let numbers = /^[-+]?[0-9]+$/
      let message = ''
      if (newValue && !newValue.match(numbers)) {
        this.$set(this, 'securityCode', oldValue)
        return
      }

      if (!this.securityCode) {
        message = this.$t('validate_credit_security_code_required')
      } else if (this.securityCode.length != this.codeMaxLength) {
        this.securityCode = this.securityCode.substring(0, this.codeMaxLength)
        message = this.$t('validate_credit_security_code_invalid')
      } else {
        message = null
      }

      this.errors.securityCode = message
    }
  },
  created () {
    const body = document.getElementsByTagName('body')[0]
    body.id = 'page-credit'

    vm = this
    this.loadScript()
    if (this.$route.params.transaction) {
      vm.transaction = this.$route.params.transaction
    }
    if (this.$route.params.payment_url) {
      var paymentUrl = atob(this.$route.params.payment_url)
    }
    var params = paymentUrl.split('?')[1].split('&')
    for (var param in params) {
      if (params[param].includes('amount')) {
        vm.total = params[param].split('=')[1]
      }
      if (params[param].includes('merchant_id')) {
        vm.merchant_id = params[param].split('=')[1]
      }
      if (params[param].includes('service_id')) {
        vm.service_id = params[param].split('=')[1]
      }
      if (params[param].includes('free1')) {
        vm.free1 = params[param].split('=')[1]
      }
      if (params[param].includes('free2')) {
        vm.free2 = params[param].split('=')[1]
      }
      if (params[param].includes('free3')) {
        vm.free3 = params[param].split('=')[1]
      }
    }
  },
  beforeDestroy: function () {
    const body = document.getElementsByTagName('body')[0]
    body.id = ''
  }
}
</script>

<style scoped>
    td {
        border: 1px dotted #719ECE !important;
    }
    table {
        width: 70%;
    }
</style>
